import styled from "styled-components"

export const ContainerDetailWrapper = styled.div`
    
    padding-top: 125px;
    width: 100%;
    margin: 0 auto;
    background-color: var(--ext-title);
    
    @media ${props => props.theme.breackpoints.mobile} {
        padding-top: 50px;
    }

    @media ${props => props.theme.breackpoints.tablet} {
        padding-right: 15px;
    }

    @media ${props => props.theme.breackpoints.desktop} {
        max-width: 1060px;
        position: relative;
    }

`

export const ContainerDetailExtWrapper = styled.div`
    
    display: grid;
    grid-row-gap: 15px;

    @media ${props => props.theme.breackpoints.tablet} {
        grid-column-gap: 10px;
        grid-template-columns: 1fr 250px;
    } 
    
    @media ${props => props.theme.breackpoints.desktop} {
        grid-column-gap: 15px;
        grid-template-columns: 1fr 325px;
    } 

`
