import styled from "styled-components"

export const ImageCountryWrapper = styled.div`
    width: 25px;
    display: flex;

    @media ${props => props.theme.breackpoints.mobile} {
        width: 35px;
    }
`
