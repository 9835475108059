import styled from "styled-components"

export const ImageAdWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    display: none;

    @media ${props => props.theme.breackpoints.mobile} {
        display: block;
    }
    @media ${props => props.theme.breackpoints.desktop} {
        max-width: 100%;
        padding: 0;
    }
`
