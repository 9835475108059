import styled from "styled-components"

export const PostWrapper = styled.div`
    display: block;
    a {
        text-decoration: none;
        color: var(--ext-purple);
        font-weight: 600;
    }
    a:focus, a:hover {
        text-decoration: underline;
    }
    p, li {
        font-size: .785em;
    }
    li {
        margin-bottom: 0;
        list-style-type: none;
    }
    code {
        color: rgb(247, 247, 247);
        background-color: rgb(161, 162, 165);
        padding: 0px 0.3rem;
    }
    blockquote p {
        font-style: italic;
        font-size: 1.5rem;
        line-height: 2.125rem;
        text-align: center;
        max-width: 36rem;
        margin: 2rem auto;
    }

    @media ${props => props.theme.breackpoints.mobile} {
        p, li {
            font-size: 1em;
        }
        blockquote p {
            margin: 3rem auto;
        }
    }
    
`

export const DetailH1 = styled.h1`
    font-size: 1.5rem;
    margin-bottom: 12px;
    @media ${props => props.theme.breackpoints.mobile} {
        font-size: 2rem;
    }
`

export const DetailP = styled.p`
    color: var(--ext-purple);
`

export const DetailDate = styled.div`
    color: var(--ext-gray-4);
    font-style: italic;
    font-size: 0.785em;
    margin-bottom: 15px;
`

export const DetailTags = styled.div`
    color: var(--ext-gray-4);
    font-size: 0.785em
`
