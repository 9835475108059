import styled from "styled-components"

export const ImageAdMobWrapper = styled.div`
    width: 100%;
    max-width: 480px;
    margin: 0 auto;

    @media ${props => props.theme.breackpoints.mobile} {
        display: none;
    }
`
