import styled from "styled-components"

//min-height: 11.25rem;
export const SocialMediaWrapper = styled.div`
    
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--ext-purple);
    padding: 5px 0;
    font-size: .8rem;
    border-top: 4px solid var(--ext-title);
    margin-top: 15px;

    @media ${props => props.theme.breackpoints.mobile} {
        position: relative;
        font-size: 1rem;
        bottom: unset;
        background-color: transparent;
        & .whatsapp {
            display: none;
        }
    }

`

export const SocialMediaNameWrapper = styled.div`
    
    flex: 1 1 auto;
    line-height: 1.2;
    & a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--ext-black-main);
    }
    & a span {
        padding-top: 2px;
    }

`

