
import styled from "styled-components"

export const WebAmigasWrapper = styled.div`
    width: 100%;  
    display: flex;
    justify-content: center;
    align-items: center;

`
//@media ${props => props.theme.breackpoints.tablet} {
    //    max-width: 700px;
    //}
