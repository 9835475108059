import styled from "styled-components"

/*export const CardWrapper = styled.article`
    display: grid;    
`*/

export const CardName = styled.div`
    color: var(--ext-black-main);
    font-weight: bold;
    font-size: .9em;
    text-shadow: 0 0 8px var(--ext-title), 0 0 8px var(--ext-purple);
    overflow: hidden;
`

export const CardCountry = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`

export const CardPrice = styled.div`
    line-height: .9;
`

export const CardCoin = styled.span`
    font-size: 1em;
`

export const CardNumber = styled.span`
    font-weight: bold;
    font-size: 1.2em;
    text-shadow: 2px 2px var(--ext-white-main);
`

export const CardNew = styled.div`
    text-align: right;
    display: flex;
    align-items: flex-end;
    color: var(--ext-white-main);
    justify-content: flex-end;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: .8em;
    font-weight: bold;
`

export const CardNewText = styled.span`
    display: flex;
    align-items: center;
    background: var(--ext-green);
    padding: 0 5px;
    border-radius: 8px;
    line-height: 1.2;
`


