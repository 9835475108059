
import styled from "styled-components"

export const ModalWrapper = styled.div`
    width: 100%;  

`
//@media ${props => props.theme.breackpoints.tablet} {
    //    max-width: 700px;
    //}
