import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export const PaginateWrapper = styled.div`
    grid-column: 2 / span 12;
    padding: 1rem 0;
    background-color: var(--ext-gray-2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.alt};
    margin-top: 25px;

    a:first-child {
        color: ${props => props.isFirst ? props.theme.colors.gray : props.theme.colors.blue};
        pointer-events: ${props => (props.isFirst ? "none" : "auto")};
        cursor: ${props => (props.isFirst ? "default" : "pointer")};
    }

    a:nth-child(2) {
        color: ${props => props.isLast ? props.theme.colors.gray : props.theme.colors.blue};
        pointer-events: ${props => (props.isLast ? "none" : "auto")};
        cursor: ${props => (props.isLast ? "default" : "pointer")};
    }

    @media ${props => props.theme.breackpoints.tablet} {
        grid-column: 2 / span 6;
    }

`

export const PaginateElement = styled(props => <Link {...props} />)`
    font-size: 0.875rem;
    line-heigth: 1.125rem;
    text-decoration: none;
    margin: 0 2rem;

    &:hover, &:focus {
        text-decoration: underline;
    }
`
