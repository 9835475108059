import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ description, keywords, title, url, image, author, lang}) => {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription = description || data.site.siteMetadata.description
                const metaTitle = title || data.site.siteMetadata.title
                const metaUrl = url || data.site.siteMetadata.url
                const metaImage = image || data.site.siteMetadata.image
                const metaAuthor = author || data.site.siteMetadata.twitterUsername
                //const metaKeywords = keywords || ["kinesiólogas extranjeras", "putas extranjeras", "kinesiólogas en lima", "kinesiólogas en perú", "kinesiologas baratas"]
                const metaKeywords = keywords || data.site.siteMetadata.keywords_default
                return (
                    <Helmet
                        htmlAttributes={{
                            lang,
                        }}
                        title={title}
                        meta={[
                            {
                                name: `description`,
                                content: metaDescription,
                            },
                            {
                                property: `og:title`,
                                content: metaTitle,
                            },
                            {
                                property: `og:description`,
                                content: metaDescription,
                            },
                            {
                                property: `og:type`,
                                content: `website`,
                            },
                            {
                                property: `og:image`,
                                content: metaImage,
                            },
                            {
                                property: `og:url`,
                                content: metaUrl,
                            },
                            {
                                name: `twitter:card`,
                                content: `summary_large_image`,
                            },
                            {
                                name: `twitter:creator`,
                                content: metaAuthor,
                            },
                            {
                                name: `twitter:title`,
                                content: metaTitle,
                            },
                            {
                                name: `twitter:description`,
                                content: metaDescription,
                            },
                            {
                                name: `twitter:image`,
                                content: metaImage,
                            },
                            {
                                name: `msapplication-TileColor`,
                                content: `#E0006C`,
                            },
                            {
                                name: `author`,
                                content: metaAuthor,
                            },
                            {
                                name: `robots`,
                                content: `index,follow`,
                            },
                            {
                                name: `googlebot`,
                                content: `index, follow`,
                            },
                            {
                                name: `language`,
                                content: `es`,
                            },
                            /*{
                                name: `google-site-verification`,
                                content: `dasdasdsadasdasdasdasasdasd`,
                            },*/
                        ].concat(
                            metaKeywords && metaKeywords.length > 0 ? {
                                name: `keywords`,
                                content: metaKeywords.join(`, `),                                
                            } : []
                        )}                        
                    >   
                        <link rel="canonical" href={metaUrl} />
                        <link rel="dns-prefetch" href="//fonts.gstatic.com" />
                        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
                        <link rel="dns-prefetch" href="//ajax.googleapis.com" />
                        <link rel="dns-prefetch" href="//www.google-analytics.com" />   
                        <script async src="https://www.googletagmanager.com/gtag/js?id=G-E3FSJHYV8M"></script>
                        <script>
                        {`document.onkeydown = function(e) {
                            if (e.ctrlKey &&
                                (e.keyCode === 67 ||
                                 e.keyCode === 86 ||
                                 e.keyCode === 85 ||
                                 e.keyCode === 117)) {
                                //alert('Chupa pija!');
                                //CTRL+C, CTRL+V y el CTRL+U
                                return false;
                            } else if (e.keyCode === 123) { // Prevent F12
                                return false;
                            } else if (e.ctrlKey && e.shiftKey && e.keyCode == 73) { // Prevent Ctrl+Shift+I        
                                return false;
                            } else {
                                return true;
                            }
                        }; 
                        function disableselect(e){
                            return false
                        }
                        function reEnable(){
                            return true
                        }
                        document.onselectstart=new Function ("return false")
                        if (window.sidebar){
                            document.onmousedown=disableselect
                            document.onclick=reEnable
                        }
                        function inhabilitar(){ 
                            var x = document.getElementById("ke_modal");
                            document.getElementsByTagName("body")[0].style.overflow = 'hidden'
                            if (x.style.display === "none") {
                                x.style.display = "flex";
                            } else {
                                x.style.display = "flex";
                            } 
                            return false 
                        }                        
                        document.oncontextmenu=inhabilitar;
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-E3FSJHYV8M');`}
                        </script>                     
                    </Helmet>
                )
            }}
        />
    )
}

export default SEO

SEO.defaultProps = {
    lang: `es`,
}

SEO.propTypes = {
    lang: PropTypes.string,
}

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
                image
                url
            }
        }
    }
`
//keywords
