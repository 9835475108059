
import styled from "styled-components"
//grid-column: 2 / span 12;
//grid-row: 1 / 2;
//display: flex;
//align-items: center;

//grid-column: 2 / span 6;
//grid-gap: 0 1rem;


export const NavWrapper = styled.div`
    
    display: block;

    @media ${props => props.theme.breackpoints.tablet} {
        display: block;
    }

`
