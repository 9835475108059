import styled from "styled-components"

//min-height: 11.25rem;
export const FooterWrapper = styled.footer`
    
    display: flex;
    grid-column: 2 / span 12;
    padding: 3rem 0 0 0;
    align-items: flex-start;
    justify-content: center;

    @media ${props => props.theme.breackpoints.tablet} {
        grid-column: 2 / span 6;
    }

`

export const FooterSocialWrapper = styled.div`
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & .credits {
        text-align: center;
        flex: 0 0 100%;
        padding-bottom: 8px;
    }

    @media ${props => props.theme.breackpoints.mobile} {
        & .credits div {
            display: inline;
        }
    }

`

export const FooterSocialIconsWrapper = styled.div`
    flex: 0 0 100%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;

    & a:last-child {
        display: inline-block;
    }

    & img {
        height: 32px;
        padding: 0 1rem;
        transition: filter 0.3s ease;
    }

    & img:hover, img: focus {
        filter: brightness(50%);
    }

    @media ${props => props.theme.breackpoints.mobile} {
        & a:last-child {
            display: none;
        }
    }
`
