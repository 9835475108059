import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

//export const CardLinkWrapper = styled.a`
export const CardLinkWrapper = styled(props => <Link {...props} />)`
    position: relative;
    display: block;
    background-color: var(--ext-gray-2);
    text-decoration: none;
    border-radius: 8px;
    overflow: hidden;
    &:before {
        content: "";
        bacground-color: var(--ext-gray-2);
        transition: .4s;
    }
    &:hover:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgb(224 0 108 / .8);
        z-index: 1;
    }
`

export const CardDescription = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20em;
    padding: 10px;
    color: var(--ext-black-main);
    z-index: 1;
    position: relative;
` 
